
@media (min-width: 319px) and (max-width: 991px) {
    .table-customResponsive {
        border: 0
    }

    .table-customResponsive thead {
        display: none
    }

    .table-customResponsive tr {
        margin-bottom: 0px;
        display: block;
        border: 2px solid #eee
    }

    .table-customResponsive tbody th,
    .table-customResponsive tbody td {
        display: block;
        font-size: 14px
    }

    .table-customResponsive tbody th:last-child,
    .table-customResponsive tbody td:last-child {
        border-bottom: 0
    }

    .table-customResponsive tbody th::before,
    .table-customResponsive tbody td::before {
        content: attr(data-label) !important;
        float: left;
        text-transform: uppercase;
        font-weight: 600;
        padding: 0;
        font-size: 0.8em;
        min-width: 115px;
        height: 53px;
        text-align: left
    }
}


@media (max-width: 991px) {
    .userMangement-tabTitles {
        display: block;
    }
}




@media (min-width: 319px) and (max-width: 991px) {
    .userMangement-table tbody tr td:after {
    content: "";
    position: absolute;
    right: 0;
    top: 15%;
    background-color: #DFE5E4;
    width: 1px;
    height: 55%;
    display: block
}
    .userMangement-table tbody tr th:after,
    .userMangement-table tbody tr td:after {
        left: 115px
    }
}


@media (min-width: 992px) {
    .userMangement .btn-disable {
        width: 100%
    }
}

.userMangement-range {
    width: 175px;
    margin-right: 10px
}

.userMangement-range select {
    height: calc(1.4em + 1.2rem + 2px);
    color: #8B9D9A;
    font-size: 12px;
    line-height: 24px
}

.userMangement-range .recordsCount {
    color: #8B9D9A;
    font-size: 12px;
    line-height: 24px
}

.userMangement-range .input-group-inside {
    top: 2px !important
}

.userMangement-table tbody tr th,
.userMangement-table thead tr th,
.userMangement-table tbody tr td {
    position: relative;
    clear: both;
    overflow: hidden;
}

.userMangement-table tbody tr th:after,
.userMangement-table tbody tr td:not(:last-child):after,
.userMangement-table thead tr th:not(:last-child):after {
    content: "";
    position: absolute;
    right: 0;
    /* left: 115px; */
    top: 15%;
    background-color: #DFE5E4;
    width: 1px;
    height: 55%;
    display: block
}


@media (min-width: 319px) and (max-width: 991px) {

    .userMangement-table tbody tr th,
    .userMangement-table tbody tr td {
        padding: 15px 5px
    }
}

.userMangement-table tbody tr th {
    font-weight: 600;
    color: #662B82
}

.userMangement-table tbody tr td {
    color: #662B82;
    text-align: left
}


.userMangement-table {
    -webkit-box-shadow: inset 0 -2px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(50, 69, 66, 0.07);
    box-shadow: inset 0 -2px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(50, 69, 66, 0.07)
}

.userMangement-table tbody td,
.userMangement-table thead th {
    vertical-align: middle
}

.userMangement-table thead tr {
    border-bottom: 2px solid rgba(191, 201, 200, 0.79)
}

.userMangement-table thead th {
    padding: 10px;
    color: #324542;
    font-size: 14px;
    line-height: 26px;
    font-weight: 600;
    text-align: left;
    border: 0
}

.userMangement-table tbody tr {
    background-color: #FFF;
    border-bottom: 1px solid rgba(50, 69, 66, 0.07)
}

.userMangement-table tbody tr th,
.userMangement-table tbody tr td {
    font-size: 13px;
    line-height: 26px;
    border: 0;
    padding: 10px
}

.userMangement-table tr td button {
    background: transparent;
    color: #D0021B;
    border: 0;
    padding: 0;
    font-size: 14px;
}

.userMangement-table tr td button:hover {
    border: 0;
    color: #D0021B;
    background-color: transparent;
}

.userMangement-table tr td button:focus,
.userMangement-table tr td button:active {
    outline: 0;
    box-shadow: none;
}