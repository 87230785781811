@import url(https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap);
html {
  position: relative;
  min-height: 100%;
}



input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    box-shadow:  0 0 0 30px white inset !important;
}




.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: -100px;
  width: 200px;
  height: 200px;
}

.container-fluid {
  padding: 70px 15px;
}




/* @import url("https://fonts.googleapis.com/css?family=Indie+Flower:300,300i,400,400i,500,700,900|Satisfy&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Amiri:ital,wght@0,400;0,700;1,400;1,700&display=swap"); */

/* line 39, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/theme-default.scss */


.expired{
  -webkit-filter: opacity(0.5);
          filter: opacity(0.5);
}


.recommendedMealsHeader {
  padding: .75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgb(187 130 118);
  border-bottom: 1px solid rgb(187 130 118);
}


.changeMealBody {
  background-color: #a4a0a121;
}


.ordersHeader {
  padding: .75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgb(220 170 160 / 66%);
  border-bottom: 1px solid rgba(0,0,0,.125);
}

.ordersBody {
  background-color: #c7897c0f;
}

.orderDetailHeader {
  padding: .75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgb(220 170 160 / 75%);
  border-bottom: 1px solid rgba(0,0,0,.125);
}

.orderDetailBody {
  background-color: #a4a0a124;
}




select:focus {
  border-color: #a4a0a1!important;
  box-shadow: 0 0 0 0.2rem rgba(199, 137, 124, 0.63)!important;
}


select option {
  background: white; 
  color: #c7897c !important;
  box-shadow: inset 20px 20px white;

} 

select option:hover {
  color: #a4a0a1; 
  box-shadow: inset 20px 20px #a4a0a1;
}



.modal{
  z-index: 10000 !important;
}

.noRadius{
  border-radius: 0px !important
}

.custom_meals:before {
  display: inline-block;
  content: " ";

}



a{
  text-decoration: none !important;
}




 .wrapper {
  display: flex;
  width: 100%;
}

#sidebar {
  width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 999;
  background: rgba(255, 255, 255, 0);
  color: #fff;
  transition: all 0.3s;
}







.homeNav{
  position: inherit !important;
}




.subsCarsoul {
  top: 48px  !important;
}


.deverHomeText{
  margin-top: 54px
}



 .locationHomeIcon {
  font-size: 133px;
  color: red;
  background: white;
}




.showTheImageOnTop {
  margin-top: 50px;
  padding-top: 50px;
}



 .Youtube {
  display: inline-block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #ff0000;
  font-size: 30px;
  color: #fff;
  text-align: center;
  line-height: 80px;
  margin-right: 25px;
  cursor: pointer;
}

.youtubPlayer{
  width: 100%;
  height :250px;
}






.YoutubePopUp{
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.modal.custom {
  text-align: center;
}

.modal-dialog.custom {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
.modal.custom:before {

display: inline-block;
vertical-align: middle;
content: " ";
height: 100%;
}



@media (max-width: 992px) {
   .carousel-inner .mutiCarousel.carousel-item > div {
      display: none;
  }
   .carousel-inner .mutiCarousel.carousel-item > div:first-child {
      display: block;
  }
}

 .carousel-inner .mutiCarousel.carousel-item.active,
 .carousel-inner .mutiCarousel.carousel-item-next,
 .carousel-inner .mutiCarousel.carousel-item-prev {
  display: flex;
  justify-content: center;
}

@media (min-width: 992px) {
   .carousel-inner .mutiCarousel.carousel-item-right.active,
   .carousel-inner .mutiCarousel.carousel-item-next {
    transform: translateX(33%);
    transition: transform 2s ease-out !important;

  }
  
   .carousel-inner .mutiCarousel.carousel-item-left.active, 
   .carousel-inner .mutiCarousel.carousel-item-prev {
    transform: translateX(33%);
    transition: transform 2s ease-out !important;

  }
}

.carousel-inner .mutiCarousel.carousel-item-right,
.carousel-inner .mutiCarousel.carousel-item-left{ 
  transform: translateX(0%);
  transition: transform 2s ease-out !important;

}


.react-multiple-carousel__arrow--right{
  right: 10px;
}
.react-multiple-carousel__arrow--left{
  left: 10px;
}



.react-multiple-carousel__arrow{

  top: 175px;


}




.carousel-control-next-icon {
  background-color: black !important;
}
.carousel-control-prev-icon {
  background-color: black !important;
}



/* .carousel-item.active {

  transition: transform 2s ease !important;
}
 */

.carousel-item {

  transition: transform 2s ease-out !important;
}












.image-cropper {
  width: 100px;
  height: 100px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}



.card{
  border: none !important;
   border-radius: 0px !important;
}




.form-control-with-icon {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    padding-left: 30px;
}

.navbar.bg-light {
    background-color: #f8f9fa !important;
    /* background-color: #f8f9faf0 !important; */
}

.CardTitleSize {
    font-size: 5vmin;
    color: #a59f9f;
}

.CardTextSizeH1 {
    font-size: 5vw;
    color: #ffffff;
}

.CardTextSizeH6 {
    font-size: 5vw;
    color: #ffffff;
}
.CardTextSizeH4 {
    font-size: 5vw;
    color: #ffffff;
}

.CardTextSizeP {
    font-size: 2.2vw;
    color: #ffffff;
}


.requiredColor{
  color:red
}
.requiredField{
  margin-bottom:0px !important;
}

.centerHeader {
    text-align: center ! important;

}

.Timer{
  top: -4px;
  /* font-family: sans-serif; */
}


.centered {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
  }

  .right {
    position: absolute;
    right: 30px;
    width: auto;
    top: 5px;
  }

  .left {
    /* position: absolute; */
    left: 30px;
    /* width: auto;
    top: 5px; */
  }
  
  .logo {
    /* margin-right: 30px; */
    /* padding: 50px; */
    display: contents !important;
  }
  
  .hideBackground {
    background-color: #ffffff00;
    /* height: 64px; */
    /* width: 166px; */
    position: relative;
    bottom: 7px;
  }
  
  .btn-circle { 
    padding: 0;
    display: inline-block;
    width: 40px; 
    height: 40px; 
    /* padding: 6px 0px;  */
    border-radius: 20px; 
    font-size: 20px; 
    text-align: center; 
    background: #ffffff00;
    border-color: #c7897c;
    color:#a4a0a1;
    border-style: dashed;
    margin-right: 46px;
    margin-left: 17px;
    font-family: 'Amiri', serif !important;
    vertical-align: 4px;

  } 
  

  .language-btn-circle { 
    padding: 0;
    display: inline-block;
    width: 40px; 
    height: 40px; 
    /* padding: 6px 0px;  */
    border-radius: 20px; 
    font-size: 20px; 
    text-align: center; 
    background: #ffffff00;
    border-color: #c7897c;
    color:#a4a0a1;
    border-style: dashed;

    margin-right: 51px;
    margin-left: 7px;
    font-family: 'Amiri', serif !important;
    vertical-align: 4px;
  } 
  .user-btn-circle { 
    /* border: none; */
    vertical-align: 4px;
    padding: 0;
    display: inline-block;
    width: 40px; 
    height: 40px; 
    border-radius: 20px; 
    font-size: 20px; 
    text-align: center; 
    background: #ffffff00;
    border-color: #c7897c;
    color:#a4a0a1;
    border-style: dashed;
    margin-right: 7px;
    margin-left: 7px;
    font-family: 'Amiri', serif !important;
  } 

  

  .logoColor{
    color: #c7897c;

  }

  .loginFooter{
    color: #a4a0a1;

  }
  .loginFooter:hover{
    color: #c7897c;

  }

  .MyMeals{
    /* position:absolute;
    z-index:2;
    list-style:none;
    text-align:left;
    padding:0;
    margin:45px 0 0 -1px; */
    /* box-shadow:1px 1px 4px rgba(0,0,0,.2);background-color:#fff; */
    /* border:1px solid #ccc; */
    /* white-space:nowrap; */
    max-height:222px;
    /* overflow-y:scroll; */
    /* height: 222px; */
    overflow-y:hidden;
    /* border-radius: 5px; */
  }

  .ShowAllMeals{
    /* box-shadow:1px 1px 4px rgba(0,0,0,.2);background-color:#fff; */
    /* border:1px solid #ccc; */
    /* white-space:nowrap; */
    max-height:-webkit-fit-content;
    max-height:-moz-fit-content;
    max-height:fit-content;
    /* overflow-y:scroll; */
    /* height: 442px; */
    overflow-y:hidden;
    /* border-radius: 5px; */
  }

  .btn.focus.hideShdow, .btn.hideShdow:focus {
    outline: 0;
    box-shadow: none;
}
/* 
  html{
      background-color: #a4a0a11f;
  }
  body{
      background-color: #a4a0a11f;
  } */



  
  /* Optional: Makes the sample page fill the window. */
  html,
  body {
    height: 100%;
    margin: 0;
    padding: 0;
  }







#map:after {
    width: 22px;
    height: 40px;
    display: block;
    content: ' ';
    position: absolute;
    top: 50%; left: 50%;
    margin: -40px 0 0 -11px;
    background: url('https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi_hdpi.png');
    background-size: 22px 40px; /* Since I used the HiDPI marker version this compensates for the 2x size */
    pointer-events: none; /* This disables clicks on the marker. Not fully supported by all major browsers, though */
}


.mymap{
  width: 100%;
  height: 350px;

}


.location-button{
line-height: 1.5;
-webkit-font-smoothing: antialiased;
/* font-family: HungerStation-Regular, "Helvetica Neue", Helvetica, Arial, sans-serif; */
-webkit-tap-highlight-color: transparent;
direction: ltr;
box-sizing: inherit;
display: inline-flex;
align-items: center;
justify-content: center;
z-index: 15;
cursor: pointer;
width: 48px;
height: 48px;
position: absolute;
top: auto;
top: initial;
bottom: 24px;
box-shadow: rgba(59, 59, 59, 0.34) 8px 12px 23px -3px;
padding: 13px;
border-radius: 50%;
background: white;
right: 14px;
border: #ffff
}


.location-icon {
  width: 22px;
  height: 22px;
  z-index: 10;
}






.autocompleteBox{
cursor: default;
-webkit-font-smoothing: antialiased;
-webkit-tap-highlight-color: transparent;
direction: ltr;
box-sizing: inherit;
height: 48px;
padding-top: 5px;
padding-bottom: 3px;
box-shadow: rgba(59, 59, 59, 0.13) 8px 12px 23px -3px;
z-index: 16;
position: absolute !important;
/* font-family: HungerStation-Light, "Helvetica Neue", Helvetica, Arial, sans-serif !important; */
background: white;
border-radius: 8px;
right: 33px;
left: 33px;
padding-left: 40px;
padding-right: 30px;
top: 5px;
color: rgba(0, 0, 0, 0.87);
display: inline-flex;
font-size: 1rem;
line-height: 1.1875em;
margin-bottom: 20px;
}

input:focus {
  outline:none !important;
}

button:focus {
  outline:none !important;
}

.autocompleteInput{
-webkit-font-smoothing: antialiased;
direction: ltr;
overflow: visible;
touch-action: manipulation;
font: inherit;
color: currentColor;
border: 0;
margin: 0;
padding: 6px 0 7px;
display: block;
min-width: 0;
flex-grow: 1;
box-sizing: content-box;
background: none;
-webkit-tap-highlight-color: transparent;
-webkit-animation-name: endBrowserAutofill;
        animation-name: endBrowserAutofill;
}

.searchBox{
line-height: 1.5;
-webkit-font-smoothing: antialiased;
/* font-family: HungerStation-Regular, "Helvetica Neue", Helvetica, Arial, sans-serif; */
-webkit-tap-highlight-color: transparent;
direction: ltr;
background-repeat: no-repeat;
box-sizing: inherit;
display: inline-flex;
align-items: center;
justify-content: center;
cursor: inherit;
position: absolute;
z-index: 17;
top: 20px;
left: 47px;
}

.search-icon{
  line-height: 1.5;
-webkit-font-smoothing: antialiased;
/* font-family: HungerStation-Regular, "Helvetica Neue", Helvetica, Arial, sans-serif; */
-webkit-tap-highlight-color: transparent;
direction: ltr;
cursor: inherit;
background-repeat: no-repeat;
box-sizing: inherit;
vertical-align: middle;
border-style: none;
width: 18px;
height: 18px;
z-index: 10;
}



.selectStyle{
  cursor: pointer;

}




button.btn.btn-block.btn-lg.btn-outline-warning.custom-button-new-address {
  color: #c7897c;
  border-color: #a4a0a1;
}


.btn-outline-warning:hover.custom-button-new-address {
  background-color: #a4a0a15e;
}

.btn-outline-warning:focus.custom-button-new-address {
  box-shadow: 0 0 0 0.2rem rgba(185, 112, 42, 0.23);
}

.btn-outline-warning:not(:disabled):not(.disabled).active, .btn-outline-warning:not(:disabled):not(.disabled):active.custom-button-new-address, .show>.btn-outline-warning.dropdown-toggle.custom-button-new-address {
  background-color: #a4a0a127;
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus.custom-button-new-address, .btn-outline-warning:not(:disabled):not(.disabled):active:focus.custom-button-new-address, .show>.btn-outline-warning.dropdown-toggle:focus.custom-button-new-address {
  box-shadow: 0 0 0 0.2rem rgba(185, 112, 42, 0.27);
}







/* .custom-notification{
  background-color: #444444;
  color:white;
  padding: 16px;
  position: absolute;
  top:16px;
  right:16px;
  z-index: 999;
  transition: top 0.5s ease;
} */

/* 
.alert.alert-danger.custom-notification {
  background-color: #444444;
  color:white;
  padding: 16px;
  position: absolute;
  top:16px;
  right:16px;
  z-index: 999;
  transition: top 0.5s ease;
} */






/* .fixed-top {
  position: static !important;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
} */


/* .fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
} */


/* .fixed-bottom-custom{
  position: fixed;
  right: -1 ;
  bottom: -1 ;
  left: -1 ;
  z-index: 1030;
} */

.myTop {
  position: fixed;
  top: -1px;
  right: -1px;
  left: -1px;
  z-index: 1030;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: .5rem 1rem;
  margin: -2px;
}

.myFooter {
  position: fixed;
  right: -1px;
  left: 0px;
  bottom: -1px;
}

.row.custom-notification{
  margin-right: 0;
  margin-left: 0;
}


.alert.alert-danger.custom-notification {
  z-index: 99999;
  position: fixed;
  transition: top .5s ease;
  right: 20px;
  left: 20px;
}

.alert.alert-success.custom-notification {
  z-index: 99999;
  position: fixed;
  transition: top .5s ease;
  right: 20px;
  left: 20px;
}

/* Autocomplet  */

.pac-container {
  background-color: #fff;
  position: absolute!important;
  z-index: 99999;
  border-radius: 2px;
  border-top: 1px solid #d9d9d9;
  /* font-family: Arial,sans-serif; */
  box-shadow: 0 2px 6px rgba(0,0,0,0.3);
  box-sizing: border-box;
  overflow: hidden;
}

.textColor{
  color: #ffffff;
  /* mix-blend-mode: exclusion; */
}

.container {
  position: relative;
}
#clip {
  position: absolute;
  clip: rect(0, 100px, 200px, 0);
}
/* clip: shape(top, right, bottom, left); NB 'rect' is the only available option */


/* div.container {
  display: inline-block;
} */
#rectangular {
  -webkit-clip-path: inset(30px 10px 30px 10px);
  clip-path: inset(30px 10px 30px 10px);
}
#circle {
  /* -webkit-clip-path: circle(75px at 50% 50%); */
  -webkit-clip-path: circle(100px at 80% 100%);
          clip-path: circle(100px at 80% 100%)
}
#ellipse {
  -webkit-clip-path: ellipse(75px 50px at 50% 50%);
  clip-path: ellipse(75px 50px at 50% 50%);
}
#polygon {
  -webkit-clip-path: polygon(50% 0, 100% 38%, 81% 100%, 19% 100%, 0 38%);
  clip-path: polygon(50% 0, 100% 38%, 81% 100%, 19% 100%, 0 38%);
}


.cardStyle{
  border-color:#b9702a !important;
  height:140px;
  overflow: hidden;
  /* border-radius:30px 0px 60px 0px; */
}



.crousolPaddingRL{
  padding-left: 0px !important;
  padding-right: 0px !important;
}









/* Select Address */

.addressesCard {
  color: black !important;;
}


















div:hover.custom-card-style.addressesCard {
  background-color: #0404040a;
  box-shadow: "0px 0px 0px 17px 0.5rem #ba7027";
  color: #c7897c
}


.rtlTextTerms {
  direction: rtl;
  text-align:right;
  font-size: 1rem;
  font-weight: 400;
  /* font-family: sans-serif; */
}
.rtlHeaderTerms {
  direction: rtl;
  text-align:right;
  /* font-family: sans-serif; */
}

.ltrTextTerms {
  direction: ltr;
  text-align:left;
  font-size: 1rem;
  font-weight: 400;
  /* font-family: sans-serif; */
}
.ltrHeaderTerms {
  direction: ltr;
  text-align:left;
  /* font-family: sans-serif; */
}



.arDir{
  direction:rtl
}

.addressColor{
  color: #c7897c;
  font-weight: bold;
}

/* Subscriptions */

/* 730 * 120 */

div {
  opacity: 1.0;
}

div.custom-card-style {
  color: #c7897c;
  border-color: #a4a0a1;
  cursor: pointer;
}


div:hover.custom-card-style {
  background-color: #ffffff4a  !important;
  /* box-shadow: 2px 10px 20px 6px rgba(181, 128, 117, 0.37) !important; */
  
}

div:focus.custom-card-style {
  box-shadow: 0 0 0 17px 0.5rem rgba(185, 112, 42, 0.23);
}


/* test */
/* div:not(:disabled):not(.disabled).active, div:not(:disabled):not(.disabled):active.custom-card-style, .show>div.dropdown-toggle.custom-card-style {
  background-color: #a4a0a127 !important;
  box-shadow: 0 0 17px 0.5rem rgba(185, 112, 42, 0.27) !important;
} */

/* div:not(:disabled):not(.disabled).active:focus.custom-card-style, div:not(:disabled):not(.disabled):active:focus.custom-card-style, .show>div.dropdown-toggle:focus.custom-card-style {
  box-shadow: 0 0 17px 0.5rem rgba(185, 112, 42, 0.27)!important;
} */




/* Select Date */



 @media screen and (min-width: 1024px){
    .responsive {
      width: 100%;
      height: auto;
      max-width: 397px;

  }
  .DayPicker {

    font-size: 1.4rem;
  }
}
/* Desktop */
@media screen and (min-width: 980px) and (max-width: 1024px){

  .DayPicker {
    font-size: 1.4rem;
  }

}
/* Tablet */
@media screen and (min-width: 760px) and (max-width: 1024px) and (min-height: 760px) and (max-height: 1366px) {

  .DayPicker {
    font-size: 3vw;
  }
}
/* Mobile HD */
@media screen and (min-width: 350px) and (max-width: 760px){


}
/* Mobile LD */
@media screen and (max-width: 350px){

  .DayPicker {
    font-size: 4vw;
  }

} 

.DayPicker {
box-shadow: -6px 2px 20px 0px rgba(181, 128, 117, 0.37);
margin-bottom: 19px
}

.DayPicker.Birthday {
box-shadow: none;
margin-bottom: 19px
}







/* Change Meals */

button.btn.btn-block.btn-lg.btn-outline-warning.custom-change-meal {
  color: #c7897c;
  border-color: #a4a0a1;
  border-radius: 0px;
  border-right: aqua;
  border-left: aqua;
  /* border-bottom: aqua; */
  border-top: aqua;
  /* background-color:#9e9e9e14; */
}



.btn-outline-warning:hover.custom-change-meal {
  background-color: #c7897c1f;
}

.btn-outline-warning:focus.custom-change-meal {
  box-shadow: 0 0 0 0.2rem rgba(185, 112, 42, 0.23);
}

.btn-outline-warning:not(:disabled):not(.disabled).active, .btn-outline-warning:not(:disabled):not(.disabled):active.custom-change-meal, .show>.btn-outline-warning.dropdown-toggle.custom-change-meal {
  background-color: #a4a0a127;
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus.custom-change-meal, .btn-outline-warning:not(:disabled):not(.disabled):active:focus.custom-change-meal, .show>.btn-outline-warning.dropdown-toggle:focus.custom-change-meal {
  box-shadow: 0 0 0 0.2rem rgba(185, 112, 42, 0.27);
}

.editIcond{
    position: absolute;
    right: 15px;
    top: 11px;
    color:#61616100;
    font-size: 22px;
    cursor:pointer;
    /* box-shadow: rgba(164, 160, 161, 0.74) -10px 10px 14px 0px; */
    text-shadow: -3px 4px 5px #0000008f;
}


.rightSide{
  position: absolute;
  right: 15px;
  top: 11px;
  color: #322929;
  font-size: 19px;
}



@media (max-width: 768px){
  .socialPadding{
    padding-top: 33px ;
  }
}


@media screen and (min-width: 769px) and (max-width: 1070px){
  .onNaveChange{
    padding-top: 80px ;
  }
}


.BarGroup{
  margin-bottom: 72px;
  margin-top: 119px;

}


@media screen and (min-width: 769px) and (max-width: 990px){
  .onNaveChangeIcons{
    padding-top: 112px ;
    padding-bottom: 20px;
  }
}
@media screen and (min-width: 991px) {
  .onNaveChangeIcons{
    padding-top: 117px ;
    padding-bottom: 20px;
  }
}


@media screen and (max-width: 768px) {
  .onNaveChangeIcons{
    padding-top: 43px ;
    padding-bottom: 20px;
  }
}


.myListLogistc{
  font-size: 14px;
  margin-left: 10px;
  list-style-type: circle;
  display: list-item
}


.socialSize{
    font-size: 22px;
}
.socialYoutube{
    color:red;
}
.socialInstagrame{
    color:black;
}
.socialLocation{
    color:black;
}


.btn-outline-warning {
  color: #c7897c !important;
  border-color: #c7897c !important;
}

.date{
    position: absolute;
    left: 15px;
    top: 11px;
    /* color:#c7897c;
    font-size: 22px;
    cursor:pointer; */

}

.editIcond:hover {
  color: #a4a0a1;
}







button.btn.btn-block.btn-lg.btn-outline-warning.custom-detail-meal {
  color: #c7897c;
  border-color: #a4a0a1;
  /* border-radius: 0px; */
  
  /* background-color:#9e9e9e14; */
}
button.btn.btn-sm.btn-outline-warning.custom-detail-meal {
  color: #c7897c;
  border-color: #a4a0a1;
  /* border-radius: 0px; */
  
  /* background-color:#9e9e9e14; */
}

.btn-outline-warning:hover.custom-detail-meal {
 background-color: #c7897c1f;
  color: #212529 !important;
  border-color: #c7897c1f !important;
}
.btn-outline-warning:focus.custom-detail-meal {
  box-shadow: 0 0 0 0.2rem rgba(185, 112, 42, 0.23);
}

.btn-outline-warning:not(:disabled):not(.disabled).active, .btn-outline-warning:not(:disabled):not(.disabled):active.custom-detail-meal, .show>.btn-outline-warning.dropdown-toggle.custom-detail-meal {
  background-color: #a4a0a127;
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus.custom-detail-meal, .btn-outline-warning:not(:disabled):not(.disabled):active:focus.custom-detail-meal, .show>.btn-outline-warning.dropdown-toggle:focus.custom-detail-meal {
  box-shadow: 0 0 0 0.2rem rgba(185, 112, 42, 0.27);
}

.login{
  margin-top: 35px
}
.custom-border{
  border: hidden !important;
}

.custom-table-header{
  color: #c7897c
}

.logout{
    background: #c7897c;
    border: none;
    color: white !important;
    border-bottom: 0px solid #eee !important;
}

@media (max-width: 769px){
  .logout {
    width: 100%;
  }
}


.activeFilter{
  color: #c7897c;
  border-color: #a4a0a1;
}

.inActiveFilter{
  color: #808080 !important;
  border-color: #00000029 !important;
}


.shadowCard{
  box-shadow: -1px 3px 9px 0px rgb(113, 81, 74);

}

.selectedItemCard{
    border-color: rgb(185, 112, 42);
    border-width: 3px;
    border-style: outset;
    width: 100%;
    border-radius: 0px;
}


.NotselectedItemCard {
  width: 100%;
  border-radius: 0px;
}


.SelectedAddress {
  /* border-width: 3px; */

  padding: 0px !important;

  border-color: rgb(185, 112, 42);
  border-style: outset;


  /* width: 92% !important; */

  margin-top: 15px;
  margin-bottom: 15px;

  box-shadow: 1px 1px 20px rgba(103, 54, 7, 0.82);
}


.underline {
  text-decoration: underline;
  cursor: pointer;
  color:#c7897c
}

@media (min-width: 576px){

  .ModalMaxWidth {
    max-width: 532px !important;
  }
  .TermsModalMaxWidth {
    max-width: 800px !important;
  }
}

.popUpMargin{
  padding-right: 0px!important;
  padding-left: 0px!important;
}
.SelectedAddressPopUP {
  /* border-width: 3px; */
  padding: 0px !important;
  border-color: rgb(201, 142, 130);
  border-style: dotted;
  /* width: 92% !important; */
  margin-top: 15px;
  /* margin-bottom: 15px; */
  /* box-shadow: 1px 1px 20px rgba(103, 54, 7, 0.82); */
}


.NotSelectedAddress {
  /* border-color: rgba(44, 44, 43, 0.678); */
  padding: 11px;

  /* border-style: outset; */
  /* width: 92% !important; */

  /* width: 100%; */
  margin-top: 15px;
  margin-bottom: 15px;

  /* box-shadow: 1px 1px 20px rgba(0,0,0,0.7); */

}

.shadowAddressCard {
  box-shadow: 14px 7px 20px rgba(0,0,0,0.7);
}

.ButtonText{
  font-size: 16px;
}



.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  -webkit-filter: blur(.7px)opacity(0.5);
          filter: blur(.7px)opacity(0.5);
  background-color: transparent;
}

.row.recomendedMeal {
  display: flex;
  flex-wrap: wrap;
  margin-right: -13px;
  margin-left: -13px;
}

.zoomIn{
  cursor: zoom-in;
}

.zoomOut{
  cursor: zoom-out
}

.row.allMealsView {
  display: flex;
  flex-wrap: wrap;
  margin-right: 5px;
  margin-left: 6px;
  margin-top: 22px;
  /* margin: 3px; */
}

.row.allMealsViewInner{
  margin-left: 4px;
  margin-right: 8px;
}

/* .allMealsShadow {
  box-shadow: 5px 6px 6px rgba(0, 0, 0, 0.37);
} */

.allMealsShadow {
  box-shadow: 5px 6px 6px rgba(181, 128, 117, 0.37);
  border-color: #c7897c85 !important;
}

.table thead th {
  vertical-align: top !important;
  border-bottom: 2px solid #dee2e6;
}


.decline-page{
  display:block;
  margin: 0 auto;
  text-align: center;
  position: relative;
  top: 240px;
  transform: perspective(1px) translateY(50%)
}

.success-page{
  display:block;
  margin: 0 auto;
  text-align: center;
  position: relative;
  transform: perspective(1px) translateY(50%)
}

.btn-view-orders{
  display: block;
  border:1px solid #47c7c5;
  width:100px;
  margin: 0 auto;
  margin-top: 45px;
  padding: 10px;
  color:#fff;
  background-color:#47c7c5;
  text-decoration: none;
  margin-bottom: 20px;
}
.success-page-h2{
  color:#47c7c5;
    margin-top: 25px;
    margin-bottom: 27px;
}
a{
  text-decoration: none;
}


.success-test {
  color: #fdc831!important;
  position: absolute;
  top: 78%;
  left: 30%;
}

.payment-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: fixed;
  left: 0;
  right: 0;
  
  height: 76%
}

.redirectTimerText{

    position: absolute;
    top: 87%;
    left: 1%;
    right: 1%;
    text-align: center;
    color: #fec15c;
    font-weight: bolder;
}

.CounterStyle{
  color: red;
}




.rr{
  box-shadow: 2px 10px 20px 6px rgba(181, 128, 117, 0.37);

}


.custom-checkout-style {
  width: 100%;
  box-shadow: 2px 10px 20px 6px rgba(181, 128, 117, 0.37);
  margin-top: 13px;
  padding-bottom: 37px;
}


.card-custom-shape{
  border-radius: 15px;
  padding: 20px;
}


.YourDeleviryLocationContainer{
  margin-top: -37px;
}




/* .col-xl-9.customWidth {
  flex: 0 0 80%;
  max-width: 80%;
} */

/* @media screen and (min-width: 1200px) {
  .col-xl-9.customWidth {
    flex: 0 0 80%;
    max-width: 80%;
  }
} */

/* @media screen and (min-width: 1200px){
  .customWidth {
    flex: 0 0 80% !important;
    max-width: 80% !important;
  }
} */

@media screen and (min-width: 375px) and (max-width: 413px){
  .customWidth {
    flex: 0 0 57% !important;
    max-width: 57% !important;
  }
}

@media screen and (min-width: 414px) and (max-width: 575px){
  .customWidth {
    flex: 0 0 61% !important;
    max-width: 61% !important;
  }
}


.stepsProcess{
    /* position: absolute; */
    left: 0;
    right: 0;
    z-index: 1000000000000000;
    top: 100px;
    margin-bottom: 18px;
    margin-top: 42px;

}


.yourAdress {
  /* font-family: cursive; */
  margin-top: -11px;
  /* margin-top: 73px; */
  font-size: 32px;
  margin-left: -30px;
  margin-right: -30px;
  margin-bottom: 18px;
  text-shadow: -12px 20px 6px #00000030;
}

.yourPlane {
  /* font-family: cursive; */
  /* margin-top: 18px; */
  margin-top: 30px;
  font-size: 32px;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 29px;
  text-shadow: -12px 20px 6px #00000030;
}

.startEndDate {
  /* font-family: cursive; */
  /* margin-top: 18px; */
  margin-top: 30px;
  font-size: 15px;
  margin-left: -30px;
  margin-right: -30px;
  margin-bottom: 29px;
  text-shadow: -12px 20px 6px #00000030;
}


.progressImage{
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  height: auto;

}



.progressImageBar{
  display: block;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  width: 16%;
  height: auto;
  margin-right: 8px;

}





@media only screen and (min-width: 1200px) {
  .iconsBar{
    font-size: 80px !important;
    text-shadow: -26px 40px 11px #0000004a;
    margin-right: 133px !important;

  }
  .labelIcon {
    top: 48px;
    left: 58px;
    position: relative;
    color: transparent;
    font-size: 79px;
    /* text-shadow: 4px -25px 12px #0000004a; */
    text-shadow: -5px -2px 17px #0000004a;

}
}

@media screen and (max-width: 1199px){
  .iconsBar{
    font-size: 79px;
    text-shadow: -22px 31px 13px #00000033;
    margin-right: 89px;

  }
}


@media screen and (min-width: 769px) and (max-width: 800px){
  .iconsBar{
    font-size: 62px;
    text-shadow: -16px 31px 8px #00000036;
    margin-right: 90px;

  }
}


@media screen and (min-width: 576px) and (max-width: 768px){
  .iconsBar{
    font-size: 65px;
    text-shadow: -16px 18px 8px #0000004a;
    margin-right: 76px;

  }
}

/* IphoneSE */
@media screen and (min-width: 320px) and (max-width: 359px){
  .iconsBar{
    font-size: 43px;
    text-shadow: -13px 20px 6px #00000033;
    margin-right: 35px;

  }
}

/* GalaxyS5 */
@media screen and (min-width: 360px) and (max-width: 374px){
  .iconsBar{
    font-size: 47px;
    text-shadow: -12px 20px 6px #00000030;
    margin-right: 36px;

  }
}

/* IphoneX */
@media screen and (min-width: 375px) and (max-width: 410px){
  .iconsBar{
    font-size: 54px;
    text-shadow: -12px 20px 6px #00000030;
    margin-right: 40px;

  }
}

/* Pixel */
@media screen and (min-width: 411px) and (max-width: 413px){
  .iconsBar{
    font-size: 55px;
    text-shadow: -14px 30px 6px #00000030;
    margin-right: 43px;

  }
}

@media screen and (min-width: 414px) and (max-width: 575px){
  .iconsBar{
    font-size: 57px;
    text-shadow: -7px 28px 8px #0000002e;
    margin-right: 43px;

  }
}



.iconsBar{
  z-index: 7;

}





.noBorderColer.form-control:focus {
  /* color: #495057; */
  /* background-color: #fff; */
  border-color: #000000!important;
  /* outline: 0; */
  box-shadow: 0 0 0 0.2rem rgba(225, 225, 225, 0)!important;
}

.icon-left-with-button {
  padding: 6px;
  min-width: 40px;
  position: absolute;
  font-size: 26px;
  z-index: 10;
  padding-left: 108px;
}
.icon-left-with-button_rtl {
  padding: 6px;
  min-width: 40px;
  position: absolute;
  font-size: 26px;
  z-index: 10;
  padding-right: 108px;
}

.verifyButton{
    min-width: 100px;
    font-size: 19px;
    z-index: 10;
    /* font-family: cursive; */
    border-radius: 0px !important;
}


.carousel-caption {
  position: absolute;
  right: 5% !important;
  bottom: 0px !important;
  left: 5%!important;
  z-index: 10;
  top: -10px;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;

}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: -13px !important;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.textCaption {
    /* mix-blend-mode: difference; */
    background: linear-gradient(90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.50) 51%,
    rgba(0, 0, 0, 0.50) 51%,
    rgba(255, 255, 255, 0) 100%);
    /* background: radial-gradient(#0000009c, #ffffff00, #ffffff00); */
}


.textCaptionBody {
    /* mix-blend-mode: difference; */
    /* font-family: "Satisfy"; */
    /* font-weight: bold; */
    color: white;
}

    i.slectedStep {
        align-self: flex-end;
        -webkit-animation-duration: 3s;
                animation-duration: 3s;
        -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite;
        transform-origin: bottom;
    }
    .slectedStep {
        -webkit-animation-name: slectedStepAnimation;
                animation-name: slectedStepAnimation;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
    }
    @-webkit-keyframes slectedStepAnimation {
        0%   { transform: translateY(0); }
        50%  { transform: translateY(-30px); }
        100% { transform: translateY(0); }
    }
    @keyframes slectedStepAnimation {
        0%   { transform: translateY(0); }
        50%  { transform: translateY(-30px); }
        100% { transform: translateY(0); }
    }


    .centerBox{
        display: flex;
        align-items: center;
        justify-content: center;
    }  



    .ButtonBorder{
      border-radius: 0px !important;
      border-color: #ff0000 !important;

    }


    .ButtonBorder:hover {
      background-color: #ff0000 !important;
      border-color: #ff0000 !important;
      color: #fff !important;
  }

  .SubNow:hover {
    color: #fff !important;

  }

    .banner {
        align-self: flex-end;
        -webkit-animation-duration: 3s;
                animation-duration: 3s;
        -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite;
        transform-origin: bottom;
    }
    .banner {
        -webkit-animation-name: bannerAnimation;
                animation-name: bannerAnimation;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
    }
    @-webkit-keyframes bannerAnimation {
        0%   {border-color:red; color: white }
        50%  {border-color:white;  color: red}
        100% {border-color:red;color: white }

        0%   { transform: scale(1,1);  }
        50%  { transform: scale(1.2,1.2);}
        100% { transform: scale(1,1);  }

        /* 0%   { transform: translateY(0); }
        50%  { transform: translateY(15px); }
        100% { transform: translateY(0); } */
    }
    @keyframes bannerAnimation {
        0%   {border-color:red; color: white }
        50%  {border-color:white;  color: red}
        100% {border-color:red;color: white }

        0%   { transform: scale(1,1);  }
        50%  { transform: scale(1.2,1.2);}
        100% { transform: scale(1,1);  }

        /* 0%   { transform: translateY(0); }
        50%  { transform: translateY(15px); }
        100% { transform: translateY(0); } */
    }




  .EditIConnRight {
    color:#c7897c;

      align-self: flex-end;
      -webkit-animation-duration: 3s;
              animation-duration: 3s;
      -webkit-animation-iteration-count: infinite;
              animation-iteration-count: infinite;
      transform-origin: bottom;
  }
  .EditIConnRight {
      -webkit-animation-name: EditIConnRightAnimation;
              animation-name: EditIConnRightAnimation;
      -webkit-animation-timing-function: ease;
              animation-timing-function: ease;
  }
  @-webkit-keyframes EditIConnRightAnimation {
      /* 0%   { transform: translateY(0); transform: scale(1,1); width: 3%; }
      50%  { transform: translateY(-7px); transform: scale(1.2,1.2); width: 4%; }
      100% { transform: translateY(0); transform: scale(1,1); width: 3%; } */
      0%   { transform: scale(1,1);  }
      50%  { transform: scale(1.2,1.2);}
      100% { transform: scale(1,1);  }

      /* 0%   { width: 34px;}
      100% { width: 44px;} */
    
  }
  @keyframes EditIConnRightAnimation {
      /* 0%   { transform: translateY(0); transform: scale(1,1); width: 3%; }
      50%  { transform: translateY(-7px); transform: scale(1.2,1.2); width: 4%; }
      100% { transform: translateY(0); transform: scale(1,1); width: 3%; } */
      0%   { transform: scale(1,1);  }
      50%  { transform: scale(1.2,1.2);}
      100% { transform: scale(1,1);  }

      /* 0%   { width: 34px;}
      100% { width: 44px;} */
    
  }



  
.labelIcon {
    top: 48px;
    left: 58px;
    position: relative;
    color: transparent;
    font-size: 79px;
    text-shadow: 19px -20px 12px #0000004a;
}

.textFieldGroup_ltr{
  padding-left: 36px !important;
}
.textFieldGroup_rtl{
  padding-right: 36px !important;
}
.phoneField_rtl{
  direction: ltr !important;
  text-align: right;
  unicode-bidi: embed
}


.phoneNumberField {
  unicode-bidi: embed;
  direction: ltr;
}
.phoneNumberField::before {
  content: 'a';
  color: transparent;
}
.phoneNumberField::after {
  content: 'a';
  color: transparent;
}

.ltr{
  direction: ltr;
  text-align: left;
}
.rtl{
  direction: rtl;
  text-align: right;
}

.localNumber{
  direction: ltr;
  unicode-bidi: embed;
}

.is-reverse_rtl {
  left: 6px;
  right: auto;
}
.is-reverse {
  left: auto;
  right: 6px;
}

.icon-eye {
  padding: 10px;
  min-width: 40px;
  position: absolute;
  font-size: 20px !important;
}


.icon-left { 
  padding: 10px; 
  min-width: 40px; 
  position:absolute;
  font-size: 19px;
  z-index: 10;
} 

.icon-right{
  padding: 10px; 
  position:absolute;
  min-width: 40px; 
  margin-left: 400px
}



.form-control.is-invalid, .was-validated .form-control:invalid {
  background-image: none !important;
}


.popUpHeader {
  /* font-family: cursive; */
  /* margin-top: 18px; */
  /* margin-top: 30px; */
  font-size: 32px;
  /* margin-left: -30px; */
  /* margin-right: -30px; */
  text-shadow: -8px 8px 6px #00000030;
    margin-bottom: 0px !important;
}


.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #672B82;
  background-color: #672B82;
}




input[type='radio']:after {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  top: -4px;
  left: -3px;
  position: absolute;
  background-color: white;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1px solid #d1d3d1;
}



input[type='radio']:checked:after {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  top: -4px;
  left: -3px;
  position: absolute;
  background-color: #c7897c;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1px solid #c7897c;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");

}




.custom-control-label::after {
  position: absolute;
  top: .25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50% / 50% 50%;
}


.form-control:focus {
  /* color: #495057; */
  /* background-color: #fff; */
  border-color: #a4a0a1!important;
  /* outline: 0; */
  box-shadow: 0 0 0 0.2rem rgba(199, 137, 124, 0.63)!important;
}




.ContactUs {
  background: white;
  padding: 23px;
  border-radius: 18px;
}

.CarsoulHome{
  position: relative;
  top: 17px;

}

.ContainerPadding {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 40px !important;
}

.HomeForm {
  background: #c98d8117;
  padding: 23px;
  border-radius: 18px;
  /* position: relative; */
  /* top: 28px; */

}

.sendEmailButton{
  background: white !important;
}



.HomeStoreLocationImg{
    position: relative;
    right: 0;
    border-radius: 2.25rem

}
/* 
.responsive {
  width: 100%;
  height: auto;
  max-width: 397px;

} */

.responsive {
  width: 100%;
  height: auto;
  /* padding-right: 0px; */
  /* padding-left: 0px; */
}

.SectionLoaderSpiner {
  -webkit-animation: App-logo-spin infinite 1s linear;
          animation: App-logo-spin infinite 1s linear;
  height: 0vmin;
  pointer-events: none;
  padding: 0px;
  font-size: 29px;
  color: #c7897c;
  z-index: 100;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}





.fontStyle{
  font-weight: bold;
}






.DayPicker-NavButton {
    position: absolute;
    top: 1em;
    right: -1px;
    left: auto;
    display: inline-block;
    margin-top: 2px;
    width: 1.25em;
    height: 1.25em;
    background-position: center;
    background-size: 50%;
    background-repeat: no-repeat;
    color: #8B9898;
    cursor: pointer;
}

.DayPickerMonth{
    margin-left: 9px !important;
  }



  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    position: relative;
    background-color: #c7897c !important;
    color: #F0F8FF!important;
  }
  
  
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: #a4a0a1 !important;
  }
  
  
  
  .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: #a4a0a12b!important;
  }



  .DayPicker-Day--highlighted {
    color: rgb(199 137 124) !important;
    background-color: rgb(199 137 124 / 10%) !important;
}
  
  /* .DayPicker-Day--disabled {
    color: rgb(220, 224, 224) !important;
    background-color: white !important;
  } */
  .DayPicker-Day--weekEndDays {
    color: rgb(220, 224, 224) !important;
    background-color: white !important;
  }
/* going live 7/13/2020 */
/* @import url("https://fonts.googleapis.com/css?family=Indie+Flower:300,300i,400,400i,500,700,900|Satisfy&display=swap"); */

/* line 39, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/theme-default.scss */
h1, h2, h3, h4, h5,h6 {
  font-family: 'Almarai', sans-serif !important;
  color: #000000;
}

body {
  font-family: 'Almarai', sans-serif !important;
  /* font-weight: normal; */
  /* font-style: normal; */
}




.line-button {
  color: #919191;
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
  position: relative;
  padding-right: 5px;
  padding-bottom: 2px;
}

/* line 204, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_btn.scss */
.line-button::before {
  position: absolute;
  content: "";
  background: #919191;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
}

/* line 213, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_btn.scss */
.line-button:hover {
  color: #009DFF;
}

/* line 216, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_btn.scss */
.line-button:hover::before {
  background: #009DFF;
}

/* line 220, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_btn.scss */
.line_btn {
  display: inline-block;
  font-size: 13px;
  color: #000000;
  border: 1px solid #c7897c;
  text-transform: capitalize;
  padding: 13px 34px;
  font-weight: 500;
  /* font-family: "Roboto", sans-serif; */
  border-radius: 30px;
  background: #a4a0a117;
}

/* line 230, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_btn.scss */
.line_btn:hover {
  background: #ce998e2b;
  color: #000000;
  border: 1px solid #000000;
}




/* line 3, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.recepie_area {
  padding-top: 14px;
  padding-bottom: 50px;
}

@media (max-width: 767px) {
  /* line 3, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_area {
    padding-top: 50px;
    padding-bottom: 0px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 3, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_area {
    padding-top: 100px;
    padding-bottom: 0px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 3, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_area {
    padding-top: 100px;
    padding-bottom: 0px;
  }
}

/* line 18, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.recepie_area.plus_padding {
  padding-bottom: 150px;
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 18, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_area.plus_padding {
    padding-bottom: 50px;
  }
}

@media (max-width: 767px) {
  /* line 18, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_area.plus_padding {
    padding-bottom: 0;
  }
}

/* line 26, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.recepie_area.plus_padding .single_recepie {
  margin-bottom: 80px;
}

@media (max-width: 767px) {
  /* line 26, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_area.plus_padding .single_recepie {
    margin-bottom: 40px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 26, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_area.plus_padding .single_recepie {
    margin-bottom: 50px;
  }
}

/* line 36, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.recepie_area.inc_padding {
  padding-bottom: 150px;
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 36, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_area.inc_padding {
    padding-bottom: 100px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 36, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_area.inc_padding {
    padding-bottom: 50px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 36, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_area.inc_padding {
    padding-bottom: 50px;
  }
}

@media (max-width: 767px) {
  /* line 36, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_area.inc_padding {
    padding-bottom: 50px;
  }
}

/* line 51, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.recepie_area .single_recepie {
  margin-bottom: 50px;
}

/* line 53, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.recepie_area .single_recepie .recepie_thumb {
  max-width: 300px;
  max-height: 300px;
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
}

@media (max-width: 767px) {
  /* line 53, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_area .single_recepie .recepie_thumb {
    width: 150px;
    height: 150px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 53, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_area .single_recepie .recepie_thumb {
    width: 150px;
    height: 150px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 53, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_area .single_recepie .recepie_thumb {
    width: 150px;
    height: 150px;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  /* line 53, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_area .single_recepie .recepie_thumb {
    width: 200px;
    height: 200px;
  }
}

/* line 75, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.recepie_area .single_recepie .recepie_thumb img {
  width: 100%;
}

/* line 79, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.recepie_area .single_recepie h3 {
  font-size: 30px;
  color: #000;
  margin-top: 38px;
  margin-bottom: 20px;
}

/* line 85, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.recepie_area .single_recepie span {
  font-size: 12px;
  color: #777777;
  font-weight: 400;
  display: block;
  margin-bottom: 5px;
}

/* line 92, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.recepie_area .single_recepie p {
  font-size: 14px;
  color: #777777;
  font-weight: 400;
  display: block;
  margin-bottom: 0;
  margin-bottom: 31px;
}


/* line 104, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.recepie_videoes_area {
  padding-top: 30px;
  padding-bottom: 115px;
}

@media (max-width: 767px) {
  /* line 104, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_videoes_area {
    padding-top: 50px;
    padding-bottom: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 104, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_videoes_area {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

/* line 115, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
/* .recepie_videoes_area .recepie_info {
  padding-right: 60px;
} */

@media (max-width: 767px) {
  /* line 115, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_videoes_area .recepie_info {
    padding-right: 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 115, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_videoes_area .recepie_info {
    margin-bottom: 40px;
  }
}

/* line 127, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.recepie_videoes_area .recepie_info h3 {
  font-size: 69px;
}

@media (max-width: 767px) {
  /* line 127, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_videoes_area .recepie_info h3 {
    font-size: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 127, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_videoes_area .recepie_info h3 {
    font-size: 40px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 127, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_videoes_area .recepie_info h3 {
    font-size: 40px;
  }
}

/* line 139, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.recepie_videoes_area .recepie_info > p {
  margin-top: 56px;
  margin-bottom: 53px;
}

/* line 145, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.recepie_videoes_area .recepie_info .video_watch a {
  display: inline-block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #ffc000;
  font-size: 30px;
  color: #fff;
  text-align: center;
  line-height: 80px;
  margin-right: 25px;
}

/* line 158, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.recepie_videoes_area .recepie_info .video_watch .watch_text h4 {
  font-size: 30px;
  margin-bottom: 7px;
}

/* line 162, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.recepie_videoes_area .recepie_info .video_watch .watch_text p {
  color: #777777;
  font-size: 14px;
  margin-bottom: 0;
}

/* line 171, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.recepie_videoes_area .videos_thumb {
  position: relative;
}

@media (max-width: 767px) {
  /* line 171, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_videoes_area .videos_thumb {
    margin-top: 40px;
  }
}

/* line 176, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.recepie_videoes_area .videos_thumb .big_img {
  padding-left: 100px;
}

@media (max-width: 767px) {
  /* line 176, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_videoes_area .videos_thumb .big_img {
    padding-left: 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 176, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_videoes_area .videos_thumb .big_img {
    padding-left: 30px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 176, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_videoes_area .videos_thumb .big_img {
    padding-left: 50px;
  }
}

/* line 187, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.recepie_videoes_area .videos_thumb .big_img img {
  width: 100%;
}

/* line 191, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.recepie_videoes_area .videos_thumb .small_thumb {
  position: absolute;
  top: 60px;
  left: 0;
}

@media (max-width: 767px) {
  /* line 191, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_videoes_area .videos_thumb .small_thumb {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 198, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_videoes_area .videos_thumb .small_thumb img {
    width: 100%;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 198, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_videoes_area .videos_thumb .small_thumb img {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 191, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_videoes_area .videos_thumb .small_thumb {
    width: 120px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 191, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_videoes_area .videos_thumb .small_thumb {
    width: 120px;
  }
}

/* line 213, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.recepie_videoes_area .videos_thumb .small_thumb_2 {
  position: absolute;
  right: 68px;
  bottom: -105px;
}

@media (max-width: 767px) {
  /* line 213, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_videoes_area .videos_thumb .small_thumb_2 {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 213, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_videoes_area .videos_thumb .small_thumb_2 {
    width: 120px;
    bottom: -50px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 213, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_videoes_area .videos_thumb .small_thumb_2 {
    width: 120px;
    bottom: -50px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 228, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_videoes_area .videos_thumb .small_thumb_2 img {
    width: 100%;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 228, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_videoes_area .videos_thumb .small_thumb_2 img {
    width: 100%;
  }
}

/* line 241, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.dish_area {
  padding-top: 15px;
  padding-bottom: 100px;
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 241, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .dish_area {
    padding-top: 0px;
    padding-bottom: 100px;
  }
}

@media (max-width: 767px) {
  /* line 241, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .dish_area {
    padding-top: 0px;
    padding-bottom: 20px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 241, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .dish_area {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

/* line 256, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.dish_area .single_dish {
  border-radius: 10px;
  border: 2px dashed #c78c83;
  padding: 76px 16px 50px 16px;
  margin-bottom: 30px;
  position: relative;
  margin-right:30px;
  margin-left:30px;
}

@media (max-width: 767px) {
  /* line 256, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .dish_area .single_dish {
    margin-bottom: 130px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 256, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .dish_area .single_dish {
    padding: 90px 42px 50px 42px;
  }
}

/* line 269, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.dish_area .single_dish .thumb {
  width: 192px;
  height: 192px;
  border-radius: 50%;
  margin: auto;
  position: absolute;
  top: -94px;
  left: 0;
  right: 0;
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 269, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .dish_area .single_dish .thumb {
    width: 130px;
    height: 130px;
    position: absolute;
    top: -66px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 284, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .dish_area .single_dish .thumb img {
    width: 100%;
  }
}

/* line 290, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.dish_area .single_dish h3 {
  font-size: 30px;
  color: #000000;
  font-weight: 400;
  margin-bottom: 24px;
}

/* line 296, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.dish_area .single_dish p {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  /* line 302, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .dish_area .single_dish:last-child {
    margin-bottom: 50px !important;
  }
}

/* line 307, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.dish_area .dish_wrap {
  margin-left: -30px;
  margin-right: -30px;
}

@media (max-width: 767px) {
  /* line 307, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .dish_area .dish_wrap {
    margin: 0;
  }
}

@media (max-width: 767px) {
  /* line 307, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .dish_area .dish_wrap {
    display: block !important;
  }
}

/* line 316, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.dish_area .dish_wrap .single_dish {
  margin: 0 30px;
}

@media (max-width: 767px) {
  /* line 316, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .dish_area .dish_wrap .single_dish {
    margin: 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 316, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .dish_area .dish_wrap .single_dish {
    margin: 0 10px;
  }
}


::selection {
  background: #c7897c;
  color: #fff;
}


/* @media (min-width: 768px){
  
  .nvaCustomContainer {
      max-width: 731px !important;
  }
} */

@media (min-width: 1070px){
  .nvaCustomContainer {
      max-width: 1140px !important;
  }
  
}
@media (min-width: 769px) and (max-width:1069px){
  .nvaCustomContainer {
      max-width: 816px !important;
  }
  
}

::-moz-selection {
  background: #c7897c;
  color: #fff;
}

.nav-link{
  cursor: pointer;
}



.header-area {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 100;
  height: 80px;
  background: #fff;
  /* background: radial-gradient(#c7897c4d, #ffffff00 , #fff); */
  /* background: rgba(30, 29, 29, 0.1); */
  /* background: radial-gradient(black, transparent); */
  transition: all .5s ease 0s;
}

.header-area .main-nav {
  min-height: 80px;
  background: transparent;
}

.header-area .main-nav .logo {
  line-height: 80px;
  color: #fff;
  font-size: 32px;
  font-weight: 800;
  text-transform: uppercase;
  float: left;
  transition: all 0.3s ease 0s;
}

.header-area .main-nav .logo em {
  font-style: normal;
  color: #c7897c;
  font-weight: 900;
}

.header-area .main-nav .nav {
  float: right;
  margin-top: 27px;
  margin-right: 0px;
  background-color: transparent;
  transition: all 0.3s ease 0s;
  position: relative;
  z-index: 999;
}

.header-area .main-nav .nav li {
  padding-left: 20px;
  padding-right: 20px;
}

.header-area .main-nav .nav li a {
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #7a7a7a;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  height: 40px;
  line-height: 27px;
  border: transparent;
  letter-spacing: 1px;
}

.header-area .main-nav .nav li a {
  color: #000;
}



.header-area .main-nav .nav li:hover a,
.header-area .main-nav .nav li a.active {
  color: #c7897c!important;
  opacity: 1;
}

.background-header .main-nav .nav li:hover a,
.background-header .main-nav .nav li a.active {
  color: #c7897c!important;
  opacity: 1;
}

.header-area .main-nav .menu-trigger {
  cursor: pointer;
  display: block;
  position: absolute;
  top: 23px;
  width: 32px;
  height: 40px;
  text-indent: -9999em;
  z-index: 99;
  right: 26px;
  display: none;
}

.header-area .main-nav .menu-trigger span,
.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  transition: all 0.4s;
  background-color: #1e1e1e;
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 0;
}



.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  transition: all 0.4s;
  background-color: #1e1e1e;
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 0;
  width: 75%;
}



.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  content: "";
}

.header-area .main-nav .menu-trigger span {
  top: 16px;
}

.header-area .main-nav .menu-trigger span:before {
  transform-origin: 33% 100%;
  top: -10px;
  z-index: 10;
}

.header-area .main-nav .menu-trigger span:after {
  transform-origin: 33% 0;
  top: 10px;
}

.header-area .main-nav .menu-trigger.active span,
.header-area .main-nav .menu-trigger.active span:before,
.header-area .main-nav .menu-trigger.active span:after {
  background-color: transparent;
  width: 100%;
}

.header-area .main-nav .menu-trigger.active span:before {
  transform: translateY(6px) translateX(1px) rotate(45deg);
  background-color: #1e1e1e;
}

.background-header .main-nav .menu-trigger.active span:before {
  background-color: #1e1e1e;
}

.header-area .main-nav .menu-trigger.active span:after {
  transform: translateY(-6px) translateX(1px) rotate(-45deg);
  background-color: #1e1e1e;
}

.background-header .main-nav .menu-trigger.active span:after {
  background-color: #1e1e1e;
}

.header-area.header-sticky {
  min-height: 80px;
  z-index: 9999;
}

.header-area.header-sticky .nav {
  margin-top: 20px !important;
}

.header-area.header-sticky .nav li a.active {
  color: #c7897c;
}

@media (max-width: 1200px) {

  .header-area .main-nav .nav li {
    padding-left: 12px;
    padding-right: 12px;
  }
  .header-area .main-nav:before {
    display: none;
  }
}


@media screen and (min-width: 769px) and (max-width: 4024px){
  .nav{
    display: flex !important
  }
}

@media (max-width: 769px) {



  .header-area .main-nav .logo {
    color: #1e1e1e;
  }
  .header-area.header-sticky .nav li a:hover,
  .header-area.header-sticky .nav li a.active {
    color: #c7897c!important;
    opacity: 1;
  }
  .header-area {
    background: #ffffff;
    padding: 0px 15px;
    height: 80px;
    box-shadow: none;
    text-align: center;
  }
  .header-area .container {
    padding: 0px;
  }
  .header-area .logo {
    margin-left: 30px;
  }
  .header-area .menu-trigger {
    display: block !important;
  }
  .header-area .main-nav {
    overflow: hidden;
  }
  .header-area .main-nav .nav {
    float: none;
    width: 100%;
    display: none;
    transition: all 0s ease 0s;
    margin-left: 0px;
  }
  .header-area .main-nav .nav li:first-child {
    border-top: 1px solid #eee;
  }

  .header-area.header-sticky .nav {
    margin-top: 0px !important;
  }
  .header-area .main-nav .nav li {
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #eee;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .header-area .main-nav .nav li a {
    height: 50px !important;
    line-height: 50px !important;
    padding: 0px !important;
    border: none !important;
    background: #f7f7f7 !important;
    color: #232d39 !important;
  }

  .header-area .main-nav .nav li a:hover {
    background: #eee !important;
    color: #c7897c!important;
  }
  .header-area .main-nav .nav li.submenu ul {
    position: relative;
    visibility: inherit;
    opacity: 1;
    z-index: 1;
    transform: translateY(0%);
    transition-delay: 0s, 0s, 0.3s;
    top: 0px;
    width: 100%;
    box-shadow: none;
    height: 0px;
  }
  .header-area .main-nav .nav li.submenu ul li a {
    font-size: 12px;
    font-weight: 400;
  }
  .header-area .main-nav .nav li.submenu ul li a:hover:before {
    width: 0px;
  }
  .header-area .main-nav .nav li.submenu ul.active {
    height: auto !important;
  }
  .header-area .main-nav .nav li.submenu:after {
    color: #3B566E;
    right: 25px;
    font-size: 14px;
    top: 15px;
  }
  .header-area .main-nav .nav li.submenu:hover ul, .header-area .main-nav .nav li.submenu:focus ul {
    height: 0px;
  }
}



@media (min-width: 319px) and (max-width: 991px) {
    .table-customResponsive {
        border: 0
    }

    .table-customResponsive thead {
        display: none
    }

    .table-customResponsive tr {
        margin-bottom: 0px;
        display: block;
        border: 2px solid #eee
    }

    .table-customResponsive tbody th,
    .table-customResponsive tbody td {
        display: block;
        font-size: 14px
    }

    .table-customResponsive tbody th:last-child,
    .table-customResponsive tbody td:last-child {
        border-bottom: 0
    }

    .table-customResponsive tbody th::before,
    .table-customResponsive tbody td::before {
        content: attr(data-label) !important;
        float: left;
        text-transform: uppercase;
        font-weight: 600;
        padding: 0;
        font-size: 0.8em;
        min-width: 115px;
        height: 53px;
        text-align: left
    }
}


@media (max-width: 991px) {
    .userMangement-tabTitles {
        display: block;
    }
}




@media (min-width: 319px) and (max-width: 991px) {
    .userMangement-table tbody tr td:after {
    content: "";
    position: absolute;
    right: 0;
    top: 15%;
    background-color: #DFE5E4;
    width: 1px;
    height: 55%;
    display: block
}
    .userMangement-table tbody tr th:after,
    .userMangement-table tbody tr td:after {
        left: 115px
    }
}


@media (min-width: 992px) {
    .userMangement .btn-disable {
        width: 100%
    }
}

.userMangement-range {
    width: 175px;
    margin-right: 10px
}

.userMangement-range select {
    height: calc(1.4em + 1.2rem + 2px);
    color: #8B9D9A;
    font-size: 12px;
    line-height: 24px
}

.userMangement-range .recordsCount {
    color: #8B9D9A;
    font-size: 12px;
    line-height: 24px
}

.userMangement-range .input-group-inside {
    top: 2px !important
}

.userMangement-table tbody tr th,
.userMangement-table thead tr th,
.userMangement-table tbody tr td {
    position: relative;
    clear: both;
    overflow: hidden;
}

.userMangement-table tbody tr th:after,
.userMangement-table tbody tr td:not(:last-child):after,
.userMangement-table thead tr th:not(:last-child):after {
    content: "";
    position: absolute;
    right: 0;
    /* left: 115px; */
    top: 15%;
    background-color: #DFE5E4;
    width: 1px;
    height: 55%;
    display: block
}


@media (min-width: 319px) and (max-width: 991px) {

    .userMangement-table tbody tr th,
    .userMangement-table tbody tr td {
        padding: 15px 5px
    }
}

.userMangement-table tbody tr th {
    font-weight: 600;
    color: #662B82
}

.userMangement-table tbody tr td {
    color: #662B82;
    text-align: left
}


.userMangement-table {
    box-shadow: inset 0 -2px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(50, 69, 66, 0.07)
}

.userMangement-table tbody td,
.userMangement-table thead th {
    vertical-align: middle
}

.userMangement-table thead tr {
    border-bottom: 2px solid rgba(191, 201, 200, 0.79)
}

.userMangement-table thead th {
    padding: 10px;
    color: #324542;
    font-size: 14px;
    line-height: 26px;
    font-weight: 600;
    text-align: left;
    border: 0
}

.userMangement-table tbody tr {
    background-color: #FFF;
    border-bottom: 1px solid rgba(50, 69, 66, 0.07)
}

.userMangement-table tbody tr th,
.userMangement-table tbody tr td {
    font-size: 13px;
    line-height: 26px;
    border: 0;
    padding: 10px
}

.userMangement-table tr td button {
    background: transparent;
    color: #D0021B;
    border: 0;
    padding: 0;
    font-size: 14px;
}

.userMangement-table tr td button:hover {
    border: 0;
    color: #D0021B;
    background-color: transparent;
}

.userMangement-table tr td button:focus,
.userMangement-table tr td button:active {
    outline: 0;
    box-shadow: none;
}
