/* going live 7/13/2020 */
/* @import url("https://fonts.googleapis.com/css?family=Indie+Flower:300,300i,400,400i,500,700,900|Satisfy&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap");

/* line 39, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/theme-default.scss */
h1, h2, h3, h4, h5,h6 {
  font-family: 'Almarai', sans-serif !important;
  color: #000000;
}

body {
  font-family: 'Almarai', sans-serif !important;
  /* font-weight: normal; */
  /* font-style: normal; */
}




.line-button {
  color: #919191;
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
  position: relative;
  padding-right: 5px;
  padding-bottom: 2px;
}

/* line 204, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_btn.scss */
.line-button::before {
  position: absolute;
  content: "";
  background: #919191;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
}

/* line 213, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_btn.scss */
.line-button:hover {
  color: #009DFF;
}

/* line 216, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_btn.scss */
.line-button:hover::before {
  background: #009DFF;
}

/* line 220, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_btn.scss */
.line_btn {
  display: inline-block;
  font-size: 13px;
  color: #000000;
  border: 1px solid #c7897c;
  text-transform: capitalize;
  padding: 13px 34px;
  font-weight: 500;
  /* font-family: "Roboto", sans-serif; */
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background: #a4a0a117;
}

/* line 230, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_btn.scss */
.line_btn:hover {
  background: #ce998e2b;
  color: #000000;
  border: 1px solid #000000;
}




/* line 3, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.recepie_area {
  padding-top: 14px;
  padding-bottom: 50px;
}

@media (max-width: 767px) {
  /* line 3, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_area {
    padding-top: 50px;
    padding-bottom: 0px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 3, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_area {
    padding-top: 100px;
    padding-bottom: 0px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 3, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_area {
    padding-top: 100px;
    padding-bottom: 0px;
  }
}

/* line 18, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.recepie_area.plus_padding {
  padding-bottom: 150px;
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 18, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_area.plus_padding {
    padding-bottom: 50px;
  }
}

@media (max-width: 767px) {
  /* line 18, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_area.plus_padding {
    padding-bottom: 0;
  }
}

/* line 26, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.recepie_area.plus_padding .single_recepie {
  margin-bottom: 80px;
}

@media (max-width: 767px) {
  /* line 26, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_area.plus_padding .single_recepie {
    margin-bottom: 40px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 26, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_area.plus_padding .single_recepie {
    margin-bottom: 50px;
  }
}

/* line 36, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.recepie_area.inc_padding {
  padding-bottom: 150px;
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 36, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_area.inc_padding {
    padding-bottom: 100px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 36, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_area.inc_padding {
    padding-bottom: 50px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 36, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_area.inc_padding {
    padding-bottom: 50px;
  }
}

@media (max-width: 767px) {
  /* line 36, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_area.inc_padding {
    padding-bottom: 50px;
  }
}

/* line 51, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.recepie_area .single_recepie {
  margin-bottom: 50px;
}

/* line 53, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.recepie_area .single_recepie .recepie_thumb {
  max-width: 300px;
  max-height: 300px;
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
}

@media (max-width: 767px) {
  /* line 53, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_area .single_recepie .recepie_thumb {
    width: 150px;
    height: 150px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 53, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_area .single_recepie .recepie_thumb {
    width: 150px;
    height: 150px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 53, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_area .single_recepie .recepie_thumb {
    width: 150px;
    height: 150px;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  /* line 53, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_area .single_recepie .recepie_thumb {
    width: 200px;
    height: 200px;
  }
}

/* line 75, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.recepie_area .single_recepie .recepie_thumb img {
  width: 100%;
}

/* line 79, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.recepie_area .single_recepie h3 {
  font-size: 30px;
  color: #000;
  margin-top: 38px;
  margin-bottom: 20px;
}

/* line 85, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.recepie_area .single_recepie span {
  font-size: 12px;
  color: #777777;
  font-weight: 400;
  display: block;
  margin-bottom: 5px;
}

/* line 92, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.recepie_area .single_recepie p {
  font-size: 14px;
  color: #777777;
  font-weight: 400;
  display: block;
  margin-bottom: 0;
  margin-bottom: 31px;
}


/* line 104, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.recepie_videoes_area {
  padding-top: 30px;
  padding-bottom: 115px;
}

@media (max-width: 767px) {
  /* line 104, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_videoes_area {
    padding-top: 50px;
    padding-bottom: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 104, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_videoes_area {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

/* line 115, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
/* .recepie_videoes_area .recepie_info {
  padding-right: 60px;
} */

@media (max-width: 767px) {
  /* line 115, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_videoes_area .recepie_info {
    padding-right: 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 115, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_videoes_area .recepie_info {
    margin-bottom: 40px;
  }
}

/* line 127, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.recepie_videoes_area .recepie_info h3 {
  font-size: 69px;
}

@media (max-width: 767px) {
  /* line 127, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_videoes_area .recepie_info h3 {
    font-size: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 127, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_videoes_area .recepie_info h3 {
    font-size: 40px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 127, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_videoes_area .recepie_info h3 {
    font-size: 40px;
  }
}

/* line 139, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.recepie_videoes_area .recepie_info > p {
  margin-top: 56px;
  margin-bottom: 53px;
}

/* line 145, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.recepie_videoes_area .recepie_info .video_watch a {
  display: inline-block;
  width: 80px;
  height: 80px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #ffc000;
  font-size: 30px;
  color: #fff;
  text-align: center;
  line-height: 80px;
  margin-right: 25px;
}

/* line 158, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.recepie_videoes_area .recepie_info .video_watch .watch_text h4 {
  font-size: 30px;
  margin-bottom: 7px;
}

/* line 162, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.recepie_videoes_area .recepie_info .video_watch .watch_text p {
  color: #777777;
  font-size: 14px;
  margin-bottom: 0;
}

/* line 171, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.recepie_videoes_area .videos_thumb {
  position: relative;
}

@media (max-width: 767px) {
  /* line 171, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_videoes_area .videos_thumb {
    margin-top: 40px;
  }
}

/* line 176, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.recepie_videoes_area .videos_thumb .big_img {
  padding-left: 100px;
}

@media (max-width: 767px) {
  /* line 176, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_videoes_area .videos_thumb .big_img {
    padding-left: 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 176, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_videoes_area .videos_thumb .big_img {
    padding-left: 30px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 176, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_videoes_area .videos_thumb .big_img {
    padding-left: 50px;
  }
}

/* line 187, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.recepie_videoes_area .videos_thumb .big_img img {
  width: 100%;
}

/* line 191, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.recepie_videoes_area .videos_thumb .small_thumb {
  position: absolute;
  top: 60px;
  left: 0;
}

@media (max-width: 767px) {
  /* line 191, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_videoes_area .videos_thumb .small_thumb {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 198, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_videoes_area .videos_thumb .small_thumb img {
    width: 100%;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 198, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_videoes_area .videos_thumb .small_thumb img {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 191, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_videoes_area .videos_thumb .small_thumb {
    width: 120px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 191, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_videoes_area .videos_thumb .small_thumb {
    width: 120px;
  }
}

/* line 213, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.recepie_videoes_area .videos_thumb .small_thumb_2 {
  position: absolute;
  right: 68px;
  bottom: -105px;
}

@media (max-width: 767px) {
  /* line 213, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_videoes_area .videos_thumb .small_thumb_2 {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 213, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_videoes_area .videos_thumb .small_thumb_2 {
    width: 120px;
    bottom: -50px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 213, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_videoes_area .videos_thumb .small_thumb_2 {
    width: 120px;
    bottom: -50px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 228, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_videoes_area .videos_thumb .small_thumb_2 img {
    width: 100%;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 228, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .recepie_videoes_area .videos_thumb .small_thumb_2 img {
    width: 100%;
  }
}

/* line 241, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.dish_area {
  padding-top: 15px;
  padding-bottom: 100px;
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 241, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .dish_area {
    padding-top: 0px;
    padding-bottom: 100px;
  }
}

@media (max-width: 767px) {
  /* line 241, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .dish_area {
    padding-top: 0px;
    padding-bottom: 20px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 241, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .dish_area {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

/* line 256, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.dish_area .single_dish {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  border: 2px dashed #c78c83;
  padding: 76px 16px 50px 16px;
  margin-bottom: 30px;
  position: relative;
  margin-right:30px;
  margin-left:30px;
}

@media (max-width: 767px) {
  /* line 256, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .dish_area .single_dish {
    margin-bottom: 130px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 256, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .dish_area .single_dish {
    padding: 90px 42px 50px 42px;
  }
}

/* line 269, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.dish_area .single_dish .thumb {
  width: 192px;
  height: 192px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  margin: auto;
  position: absolute;
  top: -94px;
  left: 0;
  right: 0;
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 269, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .dish_area .single_dish .thumb {
    width: 130px;
    height: 130px;
    position: absolute;
    top: -66px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 284, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .dish_area .single_dish .thumb img {
    width: 100%;
  }
}

/* line 290, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.dish_area .single_dish h3 {
  font-size: 30px;
  color: #000000;
  font-weight: 400;
  margin-bottom: 24px;
}

/* line 296, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.dish_area .single_dish p {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  /* line 302, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .dish_area .single_dish:last-child {
    margin-bottom: 50px !important;
  }
}

/* line 307, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.dish_area .dish_wrap {
  margin-left: -30px;
  margin-right: -30px;
}

@media (max-width: 767px) {
  /* line 307, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .dish_area .dish_wrap {
    margin: 0;
  }
}

@media (max-width: 767px) {
  /* line 307, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .dish_area .dish_wrap {
    display: block !important;
  }
}

/* line 316, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
.dish_area .dish_wrap .single_dish {
  margin: 0 30px;
}

@media (max-width: 767px) {
  /* line 316, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .dish_area .dish_wrap .single_dish {
    margin: 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 316, ../../Arafath/CL/CL October/220 Tasty Recipes/HTML/scss/_recepie.scss */
  .dish_area .dish_wrap .single_dish {
    margin: 0 10px;
  }
}
