.DayPicker-NavButton {
    position: absolute;
    top: 1em;
    right: -1px;
    left: auto;
    display: inline-block;
    margin-top: 2px;
    width: 1.25em;
    height: 1.25em;
    background-position: center;
    background-size: 50%;
    background-repeat: no-repeat;
    color: #8B9898;
    cursor: pointer;
}

.DayPickerMonth{
    margin-left: 9px !important;
  }



  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    position: relative;
    background-color: #c7897c !important;
    color: #F0F8FF!important;
  }
  
  
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: #a4a0a1 !important;
  }
  
  
  
  .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: #a4a0a12b!important;
  }



  .DayPicker-Day--highlighted {
    color: rgb(199 137 124) !important;
    background-color: rgb(199 137 124 / 10%) !important;
}
  
  /* .DayPicker-Day--disabled {
    color: rgb(220, 224, 224) !important;
    background-color: white !important;
  } */
  .DayPicker-Day--weekEndDays {
    color: rgb(220, 224, 224) !important;
    background-color: white !important;
  }